import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Notification from '@/modules/common/services/notification.service'
import Container from 'typedi'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'
@Component({
  name: 'GtrRegistrationModuleContentPagesView',
  computed: {
    ...mapState('contentpages', ['contentpages'])
  }
})
export default class GtrRegistrationModuleContentPagesView extends Vue {
  data () {
    return {
      loading: false,
      contentPagesSearch: '',
      contentPagesHeaders: [
        {
          text: 'Name',
          value: 'name',
          sortable: true
        },
        {
          text: 'Navbar Name',
          value: 'navbar_name',
          sortable: true
        },
        {
          text: 'Show in Navbar',
          value: 'show_in_navbar',
          sortable: true
        },
        {
          text: 'Display Order',
          value: 'display_order'
        },
        {
          text: 'Actions',
          value: 'actions',
          sortable: false,
          width: '64px'
        }
      ],
      contentPages: [],
      contentPageToDelete: null
    }
  }

  @Watch('contentpages')
  onContentPagesChange (payload: any) {
    this.$data.contentPages = []
    Object.entries(payload).map((entry: any) => {
      this.$data.contentPages.push({
        name: entry[0].replace(/_/g, ' '),
        navbar_name: entry[1].page_data.navbar_name.replace(/_/g, ' '),
        show_in_navbar: this.displayYesOrNo(entry[1].page_data.show_in_navbar),
        display_order: entry[1].page_data.display_order,
        value: entry[1]
      })
    })
  }

  async mounted () {
    const contentPages = this.$store.state.contentpages.contentpages
    if (Object.keys(contentPages).length > 0) {
      this.$data.contentPages = []
      Object.entries(contentPages).map((entry: any) => {
        this.$data.contentPages.push({
          name: entry[0].replace(/_/g, ' '),
          navbar_name: entry[1].page_data.navbar_name.replace(/_/g, ' '),
          show_in_navbar: this.displayYesOrNo(entry[1].page_data.show_in_navbar),
          display_order: entry[1].page_data.display_order,
          value: entry[1]
        })
      })
    } else {
      await this.fetchContentPages()
    }
  }

  displayYesOrNo (num: number) {
    return num === 1 ? 'Yes' : 'No'
  }

  handleDeleteContentPage (payload: any) {
    this.$data.contentPageToDelete = payload
  }

  handleEditContentPage (contentPage: any) {
    this.$router.push({
      name: 'level-two.modules.registration.content-pages.edit',
      params: { content_page_uuid: contentPage.value.uuid }
    })
  }

  async onDeleteContentPageAction (payload: any) {
    if (payload.confirm) {
      try {
        await this.$store.dispatch('contentpages/deleteContentPage', {
          event_uuid: this.$route.params.event_uuid,
          name: this.$data.contentPageToDelete.value.page_name
        })
        Container.get(Notification).success('Content page successfully deleted.')
      } catch (error) {
        Container.get(ErrorHandlerService).error(error)
      }
    }
    this.$data.contentPageToDelete = null
  }

  private async fetchContentPages () {
    try {
      const { event_uuid } = this.$route.params
      this.$data.loading = true
      await this.$store.dispatch('contentpages/getContentPages', { event_uuid })
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      this.$data.loading = false
    }
  }
}
